import React from 'react';
import ViewInstallationsAsSales from './view_installation_as_sales'
import ViewInstallationsAsAdmin from './view_installation_as_admin'
import ViewValuations from './view_valuations'
import AddInstallation from './add_installation'
import AddValuation from './add_valuation'
import Login from './login'
import { Container, Nav, Navbar } from 'react-bootstrap';
import Cookies from "js-cookie";

import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";

export function Main() {
  const MyRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
  return (
    <>
      <Router>
        <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark">
          <Container>
            <Navbar.Brand>
              <img
                alt=""
                src="https://oknademo.srv22208.microhost.com.pl/logo_okmar.jpg"
                width="70"
                height="30"
                className="d-inline-block align-top"
              />{' '}
              </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Item><Nav.Link href="/#/zamowienia/dodaj">Dodaj zamówienie</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link href="/#/zamowienia/administracja">Administracja</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link href="/#/wyceny/dodaj">Dodaj wycene</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link href="/#/wyceny/przegladaj">Wyceny</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link href="/new_okna.db">{Cookies.get("Rights") === "admin" ? "Kopia bazy" : ""}</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link href="/#/zaloguj">{(Cookies.get("Nick") == null) ? 'Zaloguj' : Cookies.get("Nick") + ' - zaloguj jako ktoś inny'}</Nav.Link></Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Switch>
          <MyRoute path="/zamowienia/dodaj" component={AddInstallation} />
          <MyRoute path="/zamowienia/administracja" component={ViewInstallationsAsAdmin} />
          <MyRoute path="/wyceny/dodaj" component={AddValuation} />
          <MyRoute path="/wyceny/przegladaj" component={ViewValuations} />
          <MyRoute path="/zaloguj" component={Login} />
          <MyRoute component={Login} />
        </Switch>
      </Router>
    </>
  );
}

export default Main;
