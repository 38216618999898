import { Form, Button, Row, Col, Badge } from 'react-bootstrap';
import { withRouter } from 'react-router-dom'
import axios from "axios";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import FileBase64 from "react-file-base64";

export function ViewValuations() {

    const [data, setData] = useState([{}]);
    let date = '0000-00'

    const getData = async () => {
        try {
            if (Cookies.get("Rights") === "admin" || Cookies.get("Rights") === "sales") {
                const response = await axios.get(`https://oknademo.srv22208.microhost.com.pl/get_valuations.php`);
                setData([{}]);
                setData(response.data);
            }
        } catch (e) {
            console.log(e);
        }}

    useEffect(() => {
         getData();
    }, [])


    const fill_texts_dict = {
        
        "NAME": { "label": "Nazwisko i imie", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
        "ADDRESS": { "label": "Adres", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
        "PRODUCT": { "label": "Sprzedany prod", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
        "PHONE_NUMBER": { "label": "Numer telefonu", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
        "PRICE": { "label": "Cena", "placeholder": "", "error_text": "", "data_type": "REAL", "value": 0 },
        "VALUATION_DATE": { "label": "Wycenione dnia", "placeholder": "", "error_text": "", "data_type": "DATE", "value": "" },
        "WHO_ADDED": { "label": "Kto dodał", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
      };
      const select_elements_dict = {
      //  "INVOICE_CREATED": { "label": "Faktura wypisana", "placeholder": "", "error_text": "", "data_type": "TEXT", "elements": [" ", "Tak", "Nie całkowicie", "Nie"], "value": "" },
      //  "MATERIALS_AVALIABLE": { "label": "Materiały", "placeholder": "", "error_text": "", "data_type": "TEXT", "elements": [" ", "Nie zamówione", "Zamówione", "Na stanie"], "value": "" },
      //  "ORDER_CLEARED": { "label": "Zakończone", "placeholder": "", "error_text": "", "data_type": "TEXT", "elements": [" ", "Tak", "Nie"], "value": "" },
      };

    function handleSaveEdited(installation_index) {
        let installation = data[installation_index]
        let installation_id = data[installation_index].ID
        if (installation.hasOwnProperty('ID'))
            delete installation['ID']
        axios.post(`https://oknademo.srv22208.microhost.com.pl/edit_valuation.php?id=` + installation_id,
            installation,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then(res => {
            getData()
        })
    }

    function handleDelete(installation_index) {
        let installation_id = data[installation_index].ID
        axios.get(`https://oknademo.srv22208.microhost.com.pl/delete_valuation.php?id=` + installation_id,).then(res => {
            getData()
        })
    }

    function attachments(installation_index) {

        const File1 = (files) => {
            axios
                .post(
                    `https://oknademo.srv22208.microhost.com.pl/save_valuation_attachment.php`,
                    {
                        file: files.base64,
                        filename: files.name,
                        valuation: data[installation_index].ID,
                        attachment_nr: 1
                    },
                    {
                        "Content-Type": "application/json",
                    }
                )
                .then((response) => {
                    getData()
                })
        };

        function handleDownloadAttachment(installation_index, which_attachment) {
            if (which_attachment === 1)
                if (data[installation_index].ATTACHMENT1 !== undefined) {
                    if (data[installation_index].ATTACHMENT1 !== '') {
                        window.open('https://oknademo.srv22208.microhost.com.pl/' + data[installation_index].ATTACHMENT1, "_blank");
                        return 0;
                    }
                }
        }

        function handleDeleteAttachment(installation_index, which_attachment) {
            if (which_attachment === 1)
                axios
                    .post(
                        `https://oknademo.srv22208.microhost.com.pl/delete_valuation_attachment.php`,
                        {
                            filename: data[installation_index].ATTACHMENT1,
                            valuation: data[installation_index].ID,
                            attachment_nr: which_attachment
                        },
                        {
                            "Content-Type": "application/json",
                        }
                    )
                    .then((response) => {
                        getData()
                    })
        }

        let att1 = 'Załącznik 1'
        let att1bool = false
        if (data[installation_index].ATTACHMENT1 !== undefined) {
            if (data[installation_index].ATTACHMENT1 !== '' && data[installation_index].ATTACHMENT1 !== null) {
                att1 = data[installation_index].ATTACHMENT1.substring(22, 40)
                att1bool = true
            }
        }
        return (
            <>
                <Col className="m-0 col-12 col-md-4 col-lg-2">
                    {att1bool ? <div><Button className='ml-2' variant="secondary" size='sm' onClick={() => handleDownloadAttachment(installation_index, 1)}>{att1}</Button> <Button className='ml-1 mr-3' variant="dark" size='sm' onClick={() => handleDeleteAttachment(installation_index, 1)}>X</Button></div> :
                        <FileBase64 multiple={false} onDone={File1.bind()} />}
                    
                </Col>
            </>
        );
    }

    function FillTextElement(element_key, installation_index) {
        let control_by_type = <Form.Control className="m-0" as="textarea" rows={1} type="text" defaultValue={data[installation_index][element_key]} placeholder={fill_texts_dict[element_key]["placeholder"]} onChange={(e) => { data[installation_index][element_key] = e.target.value; }} />
        if (fill_texts_dict[element_key]["data_type"] === "REAL") {
            control_by_type = <Form.Control className="m-0" type='number' step="0.1" min='0' max='999999999' defaultValue={data[installation_index][element_key]} placeholder={fill_texts_dict[element_key]["placeholder"]} onChange={(e) => { data[installation_index][element_key] = e.target.value; }} />
        }
        if (fill_texts_dict[element_key]["data_type"] === "DATE") {
            control_by_type = <Form.Control className="m-0" type='date' defaultValue={data[installation_index][element_key]} placeholder={fill_texts_dict[element_key]["placeholder"]} onChange={(e) => { data[installation_index][element_key] = e.target.value; }} />
        }
        if (element_key === "PRICE" || element_key === "WHO_ADDED") {
        
            return (
                <>
                    <Col className="m-0 col-6 col-md-3 col-lg-1">
                        <Form.Group className="m-0" controlId={element_key}>
                            <Form.Label className="m-0" size="sm">{fill_texts_dict[element_key]["label"]}</Form.Label>
                            {control_by_type}
                        </Form.Group>
                    </Col>
                </>
            );
            
        }
            return (
            <>
                <Col className="m-0 col-6 col-md-3 col-lg-2">
                    <Form.Group className="m-0" controlId={element_key}>
                        <Form.Label className="m-0" size="sm">{fill_texts_dict[element_key]["label"]}</Form.Label>
                        {control_by_type}
                    </Form.Group>
                </Col>
            </>
        );
    }

    function SelectElement(element_key, installation_index) {
        return (
            <>
                <Col className="m-0 col-4 col-md-2 col-lg-1">
                    <Form.Group className="m-0" controlId={element_key}>
                        <Form.Label className="m-0">{select_elements_dict[element_key]["label"]}</Form.Label>
                        <Form.Control className="m-0" as="select"
                            onChange={(e) => {
                                let d = data;
                                d[installation_index][element_key] = e.target.value;
                                setData(d);
                            }}
                        >
                            {
                                select_elements_dict[element_key]["elements"].map((element) => {
                                    return <option selected={element === data[installation_index][element_key] ? true : false}>{element}</option>
                                }
                                )
                            }
                        </Form.Control>
                    </Form.Group>
                </Col>
            </>
        );
    }
    let id_in_month = 0
    function columnedView(installation_index) {
        let elements = []
        for (var key in fill_texts_dict) {
            if (fill_texts_dict.hasOwnProperty(key)) {
                elements.push(FillTextElement(key, installation_index))
            }
        }

        for (var key in select_elements_dict) {
            if (select_elements_dict.hasOwnProperty(key)) {
                elements.push(SelectElement(key, installation_index))
            }
        }
        let elements0_9 = []
        let elements10_19 = []
        for (let step = 0; step < elements.length; step++) {
            if (step < 10)
                elements0_9.push(elements[step])
            else
                elements10_19.push(elements[step])
        }

        function color_by_status() {
            //if (data[installation_index].FINISHED === "Tak")
            //    return { 'background-color': '#8ae0ff', border: '2px solid #000' }
            //else if (parseFloat(data[installation_index].ADVANCE) >= parseFloat(data[installation_index].PRICE) && data[installation_index].MOUNTED === "Tak")
            //    return { 'background-color': '#8aff8a', border: '2px solid #000' }
            //else if (parseFloat(data[installation_index].ADVANCE) >= parseFloat(data[installation_index].PRICE) && data[installation_index].MOUNTED !== "Tak")
            //    return { 'background-color': '#ffca8a', border: '2px solid #000' }
            //else if (parseFloat(data[installation_index].ADVANCE) <= parseFloat(data[installation_index].PRICE) && data[installation_index].MOUNTED === "Tak")
            //    return { 'background-color': '#ffca8a', border: '2px solid #000' }
            return { 'background-color': '#fff', border: '2px solid #000' }
        }

        function idInMonth() {
            return ++id_in_month
        }

        function date_plate() {
            function calculate_number_of_installations(sign_month, index) {
                let number_of_installation_in_month = 0
                let current_sign_month = sign_month
                for (let i = index; i < data.length; i++) {
                    if (current_sign_month === sign_month) {
                        if ((i + 1) < data.length)
                            if (current_sign_month === 'jutro' || current_sign_month === 'dziś')
                                current_sign_month = 'nieokreślono'
                            else
                                current_sign_month = data[i + 1].VALUATION_DATE.substring(0, 7)
                        else
                            current_sign_month = '0000-00'
                        number_of_installation_in_month++
                    }
                    else
                        break
                }
                return number_of_installation_in_month
            }
            if (data[installation_index].VALUATION_DATE !== undefined) {
                let sign_month = data[installation_index].VALUATION_DATE.substring(0, 7)
                if (date !== sign_month) {
                    if (sign_month === 'jutro' || sign_month === 'dziś')
                        sign_month = 'nieokreślono'
                    date = sign_month
                    id_in_month = 0
                    let num = calculate_number_of_installations(sign_month, installation_index)
                    return <h2><Badge variant="success">{sign_month}</Badge> <Badge variant="success">Ilość w miesiącu {num}</Badge></h2>
                }
            }
            return <div></div>
        }
        return (
            <>
                {date_plate()}
                <div style={color_by_status()}>
                    <div style={{ margin: '-2px 2px 0px 2px' }}>
                        <Form className="m-0">
                            <Row>
                                <Col className="m-0">
                                    <h3>
                                        <Badge className="mt-0" variant="info">{idInMonth()}</Badge> <Badge className="mt-0" variant="info">{data[installation_index].ADDRESS} -- {data[installation_index].NAME}</Badge>
                                        <Button className='mx-1' variant="primary" size='sm' onClick={() => handleSaveEdited(installation_index)}>Zapisz</Button>
                                        <Button className='mr-3' variant="danger" size='sm' onClick={() => { if (window.confirm('Czy na pewno chcesz usunąć wycene ' + (installation_index + 1) + ' ?')) handleDelete(installation_index) }}>Usuń</Button>

                                    </h3>
                                </Col>
                                {attachments(installation_index)}
                            </Row>
                            <Row className="m-0">
                                {elements.map((elem) => (
                                    elem
                                ))}
                            </Row>

                        </Form>
                    </div>
                </div>
            </>
        );

    }
    let shure_clients = 0;
    function installations() {
        let installations = []
        for (let i = 0; i < data.length; i++) {
                shure_clients++
                installations.push(columnedView(i))
            
        }
        return installations
    }
    let installations_arr = []
    if (Cookies.get("Rights") === "admin" || Cookies.get("Rights") === "sales") {
        installations_arr = installations()
    }
    return (
        <>
            <link
                rel="stylesheet"
                href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
                integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
                crossorigin="anonymous"
            />

            <div>
                <h2><Badge variant="success">Ilość wycen {shure_clients}</Badge></h2>
                {installations_arr.map((elem) => (
                    elem
                ))}

            </div>
        </>
    );
}

export default withRouter(ViewValuations);
